import {ChangeDetectorRef, Component, inject, Input, signal, ViewChild, WritableSignal} from '@angular/core';
import {InitFlowbiteFix}                                                                from '../../core/decorators/flowbite.decorator';
import {MediaMatcher}                                                                   from "@angular/cdk/layout";
import {MatSidenav}                                                                     from "@angular/material/sidenav";
import {Observable}                                                                     from "rxjs";
import {NavigationEnd, Router}                                                          from "@angular/router";
import {filter}                                                                         from "rxjs/operators";
import {takeUntilDestroyed}                                                             from "@angular/core/rxjs-interop";
import {InfoService}                                                                    from "../../core/services/db/info.service";
import {ApiInfo}                                                                        from "../../core/models/db/api-info";
import {environment}                                                                    from "../../../environments/environment";

@Component({
             selector: 'app-sidebar-nav',
             templateUrl: './sidebar-nav.component.html',
             styleUrl: './sidebar-nav.component.scss'
           })
export class SidebarNavComponent {
  @Input() menuItems: any[]                = [];
  @Input() toggle: Observable<void> | null = null;
  mobileQuery: MediaQueryList;
  @ViewChild('snav') sidenav!: MatSidenav;
  routeSegments: string[]                  = [];
  activeLinks: { [key: string]: boolean }  = {};
  apiInfo: ApiInfo | null                  = null;
  version: WritableSignal<string>          = signal(' / ' + environment.version);
  protected readonly environment           = environment;
  protected readonly window                = window;
  private infoService                      = inject(InfoService);
  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher,
              private router: Router) {
    this.mobileQuery          = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.router.events
      .pipe(takeUntilDestroyed(),
            filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.routeSegments = event.urlAfterRedirects.split('/').splice(1);
        this.setActiveLink();
      });
  }

  openCanny() {
    window.open('https://homeschool-anchor.canny.io/feature-requests', '_blank');
  }

  ngOnChanges(changes: any) {
    if (changes.menuItems) {
      InitFlowbiteFix();
    }
  }

  ngOnInit() {
    if (this.toggle) {
      this.toggle.subscribe(() => {
        this.sidenav.toggle();
      });
    }
    this.infoService.get().then((info) => {
      this.apiInfo = info;
      if (this.apiInfo.version == this.environment.version) {
        this.version.set(this.apiInfo.version);
      } else {
        this.version.set(this.apiInfo.version + " / " + this.environment.version);
      }
    });
  }

  setActiveLink() {
    this.activeLinks = {};
    this.menuItems.forEach((item) => {
      if (item.link) {
        if (this.isLinkActive(item.link)) {
          this.activeLinks[item.link] = true;
        }
      }
      if (item.children) {
        item.children.forEach((child: any) => {
          if (this.isLinkActive(child.link)) {
            this.activeLinks[child.link] = true;
          }
        });
      }
    });
  }

  isLinkActive(link: string[] | string): boolean {
    if (this.routeSegments.length === 0) {
      return false;
    }
    if (Array.isArray(link)) {
      return link.every((segment, index) => this.routeSegments[index] == segment);
    } else {
      return this.routeSegments[0] === link;
    }
  }
}
