<ng-template #contentTemplate>
  @if(this.content.length > 0) {
    <div *ngFor="let line of contentLines">{{line}}</div>
  } @else {
    <ng-content></ng-content>
  }
</ng-template>
@if (title != '') {
  <div class="m-3 {{class}}">
    <mat-expansion-panel>
      <mat-expansion-panel-header class="mt-4 mb-4">
        <div class="space-x-3 items-center h-fit flex">
        <md-icon class="text-yellow min-w-6">emoji_objects</md-icon>
        <div class="flex-1">{{ title }}</div>
        </div>
      </mat-expansion-panel-header>
      <div class="flex bg-white p-4 space-x-3 rounded-xl border-tertiary border-solid">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </div>
    </mat-expansion-panel>
  </div>
} @else {
  <div class="flex bg-white p-4 space-x-3 rounded-xl m-3 border-tertiary border-2 border-solid {{class}}">
    <div>
      <md-icon class="text-yellow">emoji_objects</md-icon>
    </div>
    <div class="flex-1">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </div>
}
