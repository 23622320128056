<app-page
  [save]="save.bind(this)"
  [showBack]="true"
  details="This is where you manage basic account setup.  Advanced settings are available in the settings page."
  title="Account Management"
>
  <app-card-header
    subtitle="This is basic information about the account."
    title="Overview">
    <div class="flex flex-wrap gap-4">
      <app-text-box [(value)]="account.name" class="w-full" label="Account Name"></app-text-box>
      <app-radio [(value)]="account.type" [disabled]="account.id>0" [options]="radioOptions"></app-radio>
    </div>
    <app-button type="payments" text="Manage Payment Details" (click)="managePayments()" [disableSmallIcon]="true"></app-button>
  </app-card-header>
  <app-card-header title="Transcript Details">
    <div class="flex flex-wrap gap-4">
      <div class="flex flex-wrap gap-4 w-full">
        <div class="w-full subdetail">These are used for transcripts. The facilitator name is normally the parent that
          certifies the transcript is correct.
        </div>
        <app-text-box [(value)]="account.facilitatorTitle" label="Facilitator Title"></app-text-box>
        <app-text-box [(value)]="account.facilitator" label="Facilitator"></app-text-box>
        <app-text-box [(value)]="account.email" label="Email" type="email"></app-text-box>
        <app-text-box [(value)]="account.phone" label="Phone Number" type="tel"></app-text-box>
      </div>
      <md-divider></md-divider>
      <div class="w-full">
        <app-select [(value)]="account.timeZone" [options]="timeZoneOptions" label="Time Zone"></app-select>
        <app-callout title="How are time zones used?">
          Select the city you share a time zone with.<br/><br/>
          They are used to create scheduling for classes on the account. Changes here will not affect existing
          schedules,
          so
          make sure to update this before creating classes and schedules.
          <br/><br/>
          When users travel into other time zones, the schedule will adjust to their local time.
        </app-callout>
      </div>
    </div>
  </app-card-header>

  <app-card-header
    class="space-y-4"
    subtitle="This is the mailing address for the account."
    title="Mailing Address"
  >
    <app-text-box [(value)]="account.mailingAddress.line1" class="w-full" label="Street Address 1"></app-text-box>
    <app-text-box [(value)]="account.mailingAddress.line2" class="w-full" label="Street Address 2"></app-text-box>
    <div class="grid grid-cols-3 gap-4">
      <app-text-box [(value)]="account.mailingAddress.city" class="w-full" label="City"></app-text-box>
      <app-text-box [(value)]="account.mailingAddress.state" class="w-full" label="State"></app-text-box>
      <app-text-box [(value)]="account.mailingAddress.postalCode" class="w-full" label="Postal Code"></app-text-box>
    </div>
    <app-text-box [(value)]="account.mailingAddress.country" class="w-full" label="Country / Region"></app-text-box>
  </app-card-header>

  <app-card-header
    class="space-y-4"
    subtitle="This is the billing address for the account."
    title="Billing Address"
  >
    <app-text-box [(value)]="account.billingAddress.line1" class="w-full" label="Street Address 1"></app-text-box>
    <app-text-box [(value)]="account.billingAddress.line2" class="w-full" label="Street Address 2"></app-text-box>
    <div class="grid grid-cols-3 gap-4">
      <app-text-box [(value)]="account.billingAddress.city" class="w-full" label="City"></app-text-box>
      <app-text-box [(value)]="account.billingAddress.state" class="w-full" label="State"></app-text-box>
      <app-text-box [(value)]="account.billingAddress.postalCode" class="w-full" label="Postal Code"></app-text-box>
    </div>
    <app-text-box [(value)]="account.billingAddress.country" class="w-full" label="Country / Region"></app-text-box>
  </app-card-header>
</app-page>

<app-dialog title="Subscription" *ngIf="showSubscriptionDialog" (close)="showSubscriptionDialog = false">
  <ng-template>
  <app-button type="payments" title="Subscribe" (click)="subscribe()"></app-button>
  </ng-template>
</app-dialog>
