import {inject, NgModule}                             from '@angular/core';
import {ActivatedRouteSnapshot, RouterModule, Routes} from '@angular/router';
import {DashboardComponent}                           from './components/dashboard/dashboard.component';
import {AuthGuard}                                    from '@auth0/auth0-angular';
import {AccountsComponent}                            from './components/accounts/list/accounts.component';
import {ErrorPageComponent}                           from './components/error/error-page/error-page.component';
import {AccountDetailComponent}                       from './components/accounts/account-detail/account-detail.component';
import {AccessCode, PermissionValidationService}      from "./core/services/permission-validation.service";
import {CannyComponent}                               from "./components/redirect/canny/canny.component";
import {MainComponent}                                from "./core/components/main/main.component";
import {AppSetupService}                              from "./core/services/app-setup.service";
import {PageNotFoundComponent}                        from "./components/error/page-not-found/page-not-found.component";

export function createAccountPermissionGuard(accessCode: AccessCode[], redirectOnFail: boolean = true) {
  return (route: ActivatedRouteSnapshot) => {
    const permissionService = inject(PermissionValidationService);
    const accountId         = route.paramMap.get('accountId');
    return permissionService.authorizeAccountPermissions(Number(accountId), accessCode, redirectOnFail);
  };
}

const routes: Routes = [
  { path: '',
    component: MainComponent,
    canActivate: [AuthGuard, () => inject(AppSetupService).loadFromServer()],
    children: [
      {
        path: 'account/:accountId/transcripts',
        loadChildren: () => import('./components/reporting/reporting.module').then(m => m.ReportingModule)
      },
      {
        path: 'account/:accountId/classes',
        loadChildren: () => import('./components/class/class.module').then(m => m.ClassModule)
      },
      {
        path: 'calendar',
        loadChildren: () => import('./components/calendar/calendar.module').then(m => m.CalendarModule)
      },
      {
        path: 'assignments',
        loadChildren: () => import('./components/assignments/assignments.module').then(m => m.AssignmentsModule)
      },
      {
        path: 'account/:accountId/user',
        loadChildren: () => import('./components/users/users.module').then(m => m.UsersModule),
        canActivate: [createAccountPermissionGuard([AccessCode.ManageAccountUsers], true)],
      },
      {
        path: 'account/:accountId/time-off',
        loadChildren: () => import('./components/time-off/time-off.module').then(m => m.TimeOffModule),
        canActivate: [createAccountPermissionGuard([AccessCode.ManagePlannedTimedOff], true)],
      },
      {
        path: 'account/:accountId/settings',
        loadChildren: () => import('./components/account-settings/account-settings.module').then(m => m.AccountSettingsModule),
      },
      {path: 'dashboard', component: DashboardComponent},
      {path: '', component: DashboardComponent},
      {path: 'account/:accountId/details', component: AccountDetailComponent},
      {path: 'sso/canny', component: CannyComponent},
      {path: 'accounts', component: AccountsComponent},
      {path: 'accounts/new', component: AccountDetailComponent},
      {path: 'error', component: ErrorPageComponent},  // Wildcard route for errors
    ]
  },
  {
    path: '',
    loadComponent: () => import('./core/components/app-basic/app-basic.component').then(m => m.AppBasicComponent),
    children: [
      {
        path: 'subscription',
        loadChildren: () => import('./components/subscription/subscription.module').then(m => m.SubscriptionModule),
      },
    ]
  },
  {
    path: 'support',
    loadComponent: () => import('./core/components/main-support/main-support.component').then(m => m.MainSupportComponent),
    canActivate: [
      AuthGuard,
      () => {
        inject(AppSetupService).loadFromServer();
        inject(PermissionValidationService).authorizePermission(AccessCode.IsSuperuser, true);
      }
    ],
    children: [
      {
        path: 'rest',
        loadChildren: () => import('./components/support/hsa-support/hsa-support.module').then(m => m.HsaSupportModule),
      },
      {
        path: 'publishers',
        loadChildren: () => import('./components/support/publishers/publishers.module').then(m => m.PublishersModule),
      },
      {
        path: 'courses',
        loadChildren: () => import('./components/support/support-courses/support-courses.module').then(m => m.SupportCoursesModule),
      },
    ]
  },
  {path: '**', component: PageNotFoundComponent},  // Wildcard route for a 404 page
];

@NgModule({
            imports: [RouterModule.forRoot(routes, {bindToComponentInputs: true})],
            exports: [RouterModule],
          })
export class AppRoutingModule {
}
