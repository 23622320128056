import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import { v4 as uuid } from 'uuid';
@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss'
})
export class CheckboxComponent {
  @Input() value: boolean = false;
  @Output() valueChange                 = new EventEmitter<boolean>();
  @Input() label: string                ='';
  @HostBinding('style.display') display = 'inline-flex';
  @Input('class') class = '';
  @Input() disabled: boolean = false;
  uniqueId: string = uuid();

  onValueChange(value: boolean) {
    this.valueChange.emit(value);
  }
}
