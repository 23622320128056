<mat-card class="user-card absolute z-50">
  <mat-card-content>
    <div class="flex flex-row gap-2">
      <ngx-avatars [name]="name | name" [round]="true" [size]="40"></ngx-avatars>
      <div>
        {{ name | name }}<br/>
        <ng-container *ngIf="name.type && name.type.length > 0">{{name.type | titlecase}}</ng-container>
      </div>
    </div>
  </mat-card-content>
</mat-card>
