import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {v4 as uuid}                                          from "uuid";

export interface RadioOption {
  label: string;
  value: string;
  helpText?: string;
}

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrl: './radio.component.scss'
})
export class RadioComponent {
  @Input() disabled: boolean            = false;
  @Input() value: string                = '';
  @Output() valueChange                 = new EventEmitter<string>();
  @HostBinding('style.display') display = 'inline-flex';
  @Input('class') class                 = '';
  @Input() options: RadioOption[]       = [];
  @Input() vertical: boolean            = false;
  uniqueId: string                      = uuid();
  showHelp = false;
  protected _class: string       = '';
  helpText: string              = '';
  ngOnInit() {
    if (this.vertical){
      this._class = 'flex flex-col space-y-4';
    } else {
      this._class = 'flex flex-row';
    }
  }

  ngOnChanges(changes:any){
    if(changes.vertical){
      if (this.vertical){
        this._class = 'flex flex-col space-y-4';
      } else {
        this._class = 'flex flex-row';
      }
    }
  }

  onValueChange(value: string) {
    this.valueChange.emit(value);
  }
}
