export class Name {
  id: number;
  firstName: string;
  nickName: string;
  lastName: string;
  color: string;
  type: string;
  pictureUrl: string;

  constructor() {
    this.id         = -1;
    this.firstName  = '';
    this.nickName   = '';
    this.lastName   = '';
    this.type       = '';
    this.color      = '';
    this.pictureUrl = '';
  }
}
