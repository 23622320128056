import { Address } from "./address";

export enum AccountType {
  home = 'Homeschool',
  coop = 'Co-op'
}

export class Account {
	mailingAddress: Address;
	billingAddress: Address;
	id: number;
	name: string;
	createdAt: string;
	createdBy: number;
  type: AccountType;
  timeZone: string;
  facilitator: string;
  facilitatorTitle:string;
  email:string;
  phone:string;
  expires: string;
  billingRef: string;
  isInitiallyReviewed: boolean;

  constructor() {
		this.mailingAddress = new Address();
		this.billingAddress = new Address();
		this.id = -1;
		this.name = '';
		this.createdAt = '';
		this.createdBy = -1;
    this.type = AccountType.home;
    this.timeZone = 'America/Chicago';
    this.facilitator = '';
    this.facilitatorTitle = 'Administrator';
    this.email = '';
    this.phone = '';
    this.expires = '';
    this.billingRef = '';
    this.isInitiallyReviewed = true;
	}
}
