import {Injectable}                    from '@angular/core';
import {HttpClient}                    from "@angular/common/http";
import {ConfigService}                 from "../config.service";
import {lastValueFrom, Observable, of} from "rxjs";
import {UserAccount}                   from "../../models/db/user-account";
import {UserAccountUpdate}             from "../../models/db/user-account-update";

const CACHE_LENGTH = 60 * 1000;

@Injectable({
              providedIn: 'root'
            })
export class UserAccountService {

  private cache = new Map<number, { data: UserAccount, timestamp: number }>();

  constructor(
    private http: HttpClient,
  ) {
  }

  get url(): string {
    return '/api/account/' + ConfigService.accountId.getValue() + '/user';
  }

  public listObservable(): Observable<UserAccount[]> {
    if (ConfigService.accountId.getValue() == null) {
      return of([]);
    }
    return this.http.get<UserAccount[]>(this.url);
  }

  public async list(): Promise<UserAccount[]> {
    if (ConfigService.accountId.getValue() == null) {
      return [];
    }
    const response = this.http.get<UserAccount[]>(this.url);
    return await lastValueFrom(response);
  }

  public async create(object: UserAccountUpdate): Promise<UserAccount> {
    const response = this.http.post<UserAccount>(this.url, object);
    return await lastValueFrom(response);
  }

  public async update(object: UserAccountUpdate): Promise<UserAccount> {
    this.cache.delete(object.userId);
    const response = this.http.put<UserAccount>(this.url, object);
    return await lastValueFrom(response);
  }

  public async delete(id: number): Promise<any> {
    this.cache.delete(id);
    const response = this.http.delete(this.url + '/' + id);
    return await lastValueFrom(response);
  }
private static CACHE_LENGTH = 60 * 1000;
  public async get(id: number): Promise<UserAccount> {
    const now        = Date.now();
    const cacheEntry = this.cache.get(id);
    if (cacheEntry && now - cacheEntry.timestamp < CACHE_LENGTH) {
      // Return a deep copy of the cached user
      return JSON.parse(JSON.stringify(cacheEntry.data));
    } else {
      const response = this.http.get<UserAccount>(this.url + '/' + id);
      const data     = await lastValueFrom(response);
      if (data) {
        this.cache.set(id, {data, timestamp: now});
      } else {
        this.cache.delete(id);
      }
      return data;
    }
  }

  public acceptInvite(accountId: number): Promise<any> {
    const response = this.http.post('/api/account/' + accountId + '/user/invite/accept', '');
    return lastValueFrom(response);
  }

  public declineInvite(accountId: number): Promise<any> {
    const response = this.http.post('/api/account/' + accountId + '/user/invite/decline', '');
    return lastValueFrom(response);
  }

  public resendInvite(userId: number): Promise<any> {
    const response = this.http.post('/api/account/' + ConfigService.accountId.getValue() + '/user/invite/resend/' + userId, '');
    return lastValueFrom(response);
  }
}
