<app-page *ngIf="user" class="pb-8" helpUrl="https://www.homeschoolanchor.com/help/dashboard/" title="Dashboard">
  <h2>Welcome {{ user.nickName == '' ? user.firstName : user.nickName }}</h2>

  @if (noAccount) {
    <app-callout>
      You do not have a home account account yet.
      <br/><br/>
      Every user must have one home account. Please create one in your account settings, or accept an invite from an
      existing account.
    </app-callout>
  } @else {
    @if (this.setupProgress && (
      !this.setupProgress.timeOff || !this.setupProgress.schoolYears || !this.setupProgress.students || !this.setupProgress.classes
    )) {
      <app-callout>
        Finish Setting Up Your Account<br/><br/>
        You just need to do the following things, and you'll be on your way!<br/>
        <ol class="list-decimal list-outside pl-5">

          <li [ngClass]="{'line-through': this.setupProgress.timeOff}">
            <a [routerLink]="routeGenService.getTimeOff()">
              Add in time off dates for your school year
            </a>
          </li>
          <li [ngClass]="{'line-through': this.setupProgress.schoolYears}">
            <a [routerLink]="routeGenService.getNewSchool()">
              Setup a school year
            </a>
          </li>
          <li [ngClass]="{'line-through': this.setupProgress.students}">
            <a [routerLink]="routeGenService.getNewUser()">
              Invite a student
            </a>
          </li>
          <li [ngClass]="{'line-through': this.setupProgress.classes}">
            <a [routerLink]="routeGenService.getNewClass()">
              Create a class
            </a>
          </li>
        </ol>
        <br/>
        If you need live one-on-one assistance getting started, click here to schedule.<br/>
        <app-help-link link="https://calendly.com/homeschool-anchor/30min" label="Schedule Walkthrough"></app-help-link>
      </app-callout>
    }
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
      <app-card-header [isLoading]="isTodayLoading"
                       class="" title="Today">
        <div *ngIf="weatherData">
          <div>
            <a [href]="weatherData.detailsUrl" class="flex space-x-4" target="_blank">
              <img [matTooltip]="weatherData.main" [src]="weatherData.iconUrl"/>
              <div class="flex flex-col">
                <span>Current Temp: {{ weatherData.temp | number:'1.0-0' }}°F</span>
                <span>Low: {{ weatherData.tempLow | number:'1.0-0' }}
                  °F High: {{ weatherData.tempHigh | number:'1.0-0' }}
                  °F</span>
              </div>
            </a>
          </div>
          <md-divider></md-divider>
        </div>
        <div *ngIf="today" class="flex flex-col gap-4">
          <a [routerLink]="'/assignments'">
            Total Assignments Today: {{ today.dueToday }}
          </a>
          <a [routerLink]="'/assignments'">
            Total Past Due: <span [class]="today.pastDue > 0 ? 'text-red-500' : ''">{{ today.pastDue }}</span>
          </a>
          <md-divider></md-divider>
          <span>Graded Assignments Due Today: {{ today.gradedDueToday }}</span>
          <span>Graded Assignments Past Due: {{ today.gradedPastDue }}</span>
          <span>Completed Assignments Needing Grades: {{ today.needsGrades }}</span>
          <app-button (click)="showRescheduleDialog = true;"
                      *ngIf="assignmentsToday > 0"
                      [disableSmallIcon]="true"
                      text="Cancel School Today"
                      type="cancel"></app-button>
        </div>
      </app-card-header>

      <app-card-header
        *ngIf="schoolYear && schoolYear.id"
        [isLoading]="isSummaryLoading"
        title="Academic Progress">
        <div class="flex flex-col">
          <a [routerLink]="routeGenService.getSchoolYear(schoolYear.id)">
            <span>Days left of the school year: {{ daysLeft }} </span>
          </a>
        </div>
        <table>
          <tr>
            <th>Active Classes</th>
            <th>Assignment Days Left</th>
          </tr>
          @for (clazz of clazzSummaries; track clazz.clazzId) {
            <tr>
              <td><a [routerLink]="routeGenService.getClassAssignments(clazz.clazzId)">{{ clazz.clazzName }}</a></td>
              <td><a
                [routerLink]="routeGenService.getClassAssignments(clazz.clazzId)">{{ clazz.assignmentsRemaining }}</a>
              </td>
            </tr>
          }
        </table>
      </app-card-header>

      <app-card-header *ngIf="user.weightedGpa || classSummaries.length > 0"
                       [isLoading]="isSummaryLoading"
                       class="flex flex-col"
                       title="My Grades">
      <span>GPA:
        @if (user.weightedGpa) {
          {{ user.weightedGpa }}
        } @else {
          No GPA yet
        }
      </span>
        <ng-container *ngIf="classSummaries.length > 0">
          <md-divider></md-divider>
          <div class="flex flex-col">
            @for (clazz of classSummaries; track clazz.clazzId) {
              <span>
              {{ clazz.clazzName }}:
                @if (clazz.percent) {
                  {{ clazz.percent | percent:'0.1-1' }}
                } @else {
                  No grade yet
                }
            </span>
            }
          </div>
        </ng-container>
      </app-card-header>

      @for (student of studentSummaries; track student.name.id) {
        <app-card-header title="{{student.name | name}} ({{student.pastDue}})"
                         [backgroundColor]="student.name.color"
                         [thumbnailUrl]="student.name.pictureUrl">
          <div class="flex flex-col gap:4">
            <span>Past Due: {{ student.pastDue }}</span>
            <span>Due Today: {{ student.dueToday }}</span>
            <span>On Time Streak: {{ student.onTrackStreak }}</span>
            <span>Longest Streak: {{ student.longestStreak }}</span>
            <md-divider></md-divider>
            <span>Current Lowest Grade</span>
            @if (student.lowestClazzName) {
              <a style="color: inherit" [routerLink]="routeGenService.getClassGrades(student.lowestClazzId)">
                <span>{{ student.lowestClazzName }}: {{ student.lowestPercentage | percent:'0.1-1' }}</span>
              </a>
            } @else {
              <span>No Grades Entered</span>
            }
          </div>
        </app-card-header>
      }
    </div>
    <app-card-header
      class="hidden" title="Attendance">
      <h3>Days Absent: 5</h3>
      <h3>Days Tardy: 3</h3>
      <h3>Days Present: 2</h3>
    </app-card-header>
  }
  <app-feature-request></app-feature-request>
</app-page>
<app-dialog *ngIf="account && !account.isInitiallyReviewed" [allowClose]="false" title="Confirm Details">
  <ng-template>
    Please confirm the following information is correct:
    <app-text-box [(value)]="account.name" class="w-64 md:w-80"
                  helpText="This is your school name.  It will show up on reports like transcripts.<br/><br/>You can change it later under your account settings.<br/><br/>Ex: Smith Family Homeschool"
                  label="School Name"></app-text-box>
    <app-select [(value)]="account.timeZone" [options]="timeZoneOptions" class="w-64 md:w-80"
                helpText="This is used in calendar functionality.<br/><br/>You can change it later under your account settings."
                label="Time Zone"></app-select>
    <div class="flex">
      <div class="flex-1"></div>
      <app-button (click)="accountReviewed()" text="Confirm" type="save"></app-button>
    </div>
  </ng-template>
</app-dialog>
<!--
<app-dialog-reschedule (close)="closeCancel();" *ngIf="showRescheduleDialog"></app-dialog-reschedule>
-->

