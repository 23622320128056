<div (click)="$event.stopPropagation()" class="w-full h-full">
@if (disableSmallIcon) {
  <div>
    <md-filled-button (click)="onClick(); $event.stopPropagation()" [disabled]="disabled">
      <md-icon slot="icon">{{ icon_name }}</md-icon>
      <span>{{ text }}</span>
    </md-filled-button>
  </div>
} @else if (iconOnly == false) {
  <div class="hidden md:inline">
    <md-filled-button (click)="onClick(); $event.stopPropagation()" [disabled]="disabled" [trailingIcon]="type=='next'">
      <md-icon slot="icon">{{ icon_name }}</md-icon>
      <span>{{ text }}</span>
    </md-filled-button>
  </div>
  <div class="md:hidden">
    <md-filled-icon-button (click)="onClick(); $event.stopPropagation()" [disabled]="disabled">
      <md-icon>{{ icon_name }}</md-icon>
    </md-filled-icon-button>
  </div>
} @else {
  <div>
    <md-filled-icon-button (click)="onClick(); $event.stopPropagation()" [disabled]="disabled">
      <md-icon>{{ icon_name }}</md-icon>
    </md-filled-icon-button>
  </div>
}
</div>
