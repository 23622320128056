import {Component, inject}           from '@angular/core';
import {AccountService}              from 'src/app/core/services/db/account.service';
import {from}                        from 'rxjs';
import {Router}                      from '@angular/router';
import {TableColumn}                 from 'src/app/core/models/ui/table-column';
import {PaymentService}              from "../../../core/services/db/payment.service";
import {PermissionValidationService} from "../../../core/services/permission-validation.service";
import {AccountTypePipe}             from "../../../core/pipes/account-type.pipe";

declare var Chargebee: any;

@Component({
             selector: 'app-accounts',
             templateUrl: './accounts.component.html',
             styleUrls: ['./accounts.component.scss'],
           })
export class AccountsComponent {
  permissionValidationService              = inject(PermissionValidationService);
  private paymentService                   = inject(PaymentService);
  private accountTypePipe                  = inject(AccountTypePipe);
  public accountListHeaders: TableColumn[] = [
    {
      columnDef: 'type', header: 'Type', type: 'text', pipe: (i) => {
        return this.accountTypePipe.transform(i)
      }
    },
    {columnDef: 'name', header: 'Name', type: 'link', urlTemplate: '/account/{value}/details', urlValueColumn: 'id'},
    {columnDef: 'createdAt', header: 'Created', type: 'dateTime'},
    {
      columnDef: 'actions', header: 'Actions', type: 'buttons',
      //visible: (element: any) => this.permissionValidationService.authorizeAccountPermissions(element.id, [AccessCode.ManageAccountSettings]),
      buttons: [
        {
          type: 'edit',
          iconOnly: true,
          clicked: (element: any) => {
            this.router.navigate(['account', element.id, 'details'])
          },
        },
        {
          type: 'payments',
          iconOnly: false,
          clicked: (element: any) => {
            const cbInstance = Chargebee.init({
                                                site: 'homeschoolanchor-test',
                                                publishableKey: 'test_fTlWLcDh8VoOiBFoSIB4cdzIgN5jsPzIu'
                                              });
            cbInstance.setPortalSession(() => {
              return this.paymentService.getPortalUrl(element.id)
            });
            let cbPortal = cbInstance.createChargebeePortal();
            cbPortal.open({
                            close() {
                              cbInstance.logout();
                            }
                          });
          },
        },
      ]
    }
  ];

  constructor(
    private accountService: AccountService,
    private router: Router) {
    const cbInstance = Chargebee.init({
                                        site: 'homeschoolanchor-test',
                                        publishableKey: 'test_fTlWLcDh8VoOiBFoSIB4cdzIgN5jsPzIu'
                                      });
    //this.showListOnPermissions();
  }

  getAccounts() {
    return from(this.accountService.getAccounts());
  }

  create() {
    this.router.navigate(['/accounts/new']);
  }

  async showListOnPermissions() {
    this.accountListHeaders = [
      {columnDef: 'name', header: 'Name', type: 'link', urlTemplate: '/account/{value}/details', urlValueColumn: 'id'},
      {columnDef: 'createdAt', header: 'Created', type: 'dateTime'},
      {
        columnDef: 'actions', header: 'Actions', type: 'buttons',
        //visible: (element: any) => this.permissionValidationService.authorizeAccountPermissions(element.id, [AccessCode.ManageAccountSettings]),
        buttons: [
          {
            type: 'edit',
            iconOnly: true,
            clicked: (element: any) => {
              this.router.navigate(['account', element.id, 'details'])
            },
          },
          {
            type: 'payments',
            iconOnly: false,
            clicked: (element: any) => {
              const cbInstance = Chargebee.init({
                                                  site: 'homeschoolanchor-test',
                                                  publishableKey: 'test_fTlWLcDh8VoOiBFoSIB4cdzIgN5jsPzIu'
                                                });
              cbInstance.setPortalSession(() => {
                return this.paymentService.getPortalUrl(element.id)
              });
              let cbPortal = cbInstance.createChargebeePortal();
              cbPortal.open({
                              close() {
                                cbInstance.logout();
                              }
                            });
            },
          },
        ]
      }
    ];
  }
}
