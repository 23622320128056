import {Component, Input} from '@angular/core';
import {Name}             from "../../core/models/db/name";

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrl: './user-card.component.scss'
})
export class UserCardComponent {
  @Input() name: Name = new Name();
  @Input() showCard = false;

  toggleCard() {
    this.showCard = !this.showCard;
  }
}
