export class Address {
	id: number;
	line1: string;
	line2: string;
	city: string;
	state: string;
	postalCode: string;
	country: string;

	constructor() {
		this.id = -1;
		this.line1 = '';
		this.line2 = '';
		this.city = '';
		this.state = '';
		this.postalCode = '';
		this.country = '';
	}
}