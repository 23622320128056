<div [class.isMobile]="mobileQuery.matches" class="h-screen flex flex-col">
  <mat-toolbar class="!bg-primary !text-white flex-none" id="avatar-menu">
    <mat-toolbar-row>
      <md-icon-button #hamMenu (focus)="hamMenu.blur()" (click)="toggleNavbar.emit()">
        <md-icon class="hamMenu">menu</md-icon>
      </md-icon-button>
      <h1 *ngIf="nonProdEnvironment && nonProdEnvironment.length > 0">
        Homeschool Anchor
      </h1>
      <h1 *ngIf="!nonProdEnvironment || nonProdEnvironment.length == 0">
        Homeschool Anchor
      </h1>
      <span class="flex-auto"></span>
      <mat-icon
        *ngIf="user"
        [matMenuTriggerFor]="userMenu"
        [matTooltip]="user | name"
        id="avatar-icon">
        <ngx-avatars size="56"
                     [name]="user | name"
                     [title]="user | name"
                     borderColor="#c7c7c7"
                     [bgColor]="user.color"
                     [src]="user.profileThumbnailUrl">
        </ngx-avatars>
      </mat-icon>
      <mat-menu #userMenu="matMenu">
        <button mat-menu-item *ngIf="user">
          {{ user | name }}
        </button>
        <a (click)="scheduleSupport()" class="menu-item">
          <md-icon>help_center</md-icon>
          <span>Schedule Support</span>
        </a>
        <a (click)="openCanny()" class="menu-item">
          <md-icon>help_center</md-icon>
          <span>Feature Requests</span>
        </a>
        <a routerLink="/support" class="menu-item" *ngIf="user?.isSuperuser">
          <md-icon>support</md-icon>
          <span>Support Site</span>
        </a>
        <a (click)="logout()" class="menu-item">
          <md-icon>logout</md-icon>
          <span>Logout</span>
        </a>
      </mat-menu>
    </mat-toolbar-row>
  </mat-toolbar>

  <app-sidebar-nav [menuItems]="menuItems" [toggle]="toggleNavbar" class="flex-1">
    <mat-form-field *ngIf="displayedAccounts.length > 0">
      <mat-label>Account</mat-label>
      <mat-select
        (selectionChange)="setSelected()"
        [(value)]="boundAccountId">
        <mat-option *ngFor="let account of displayedAccounts" [value]="account.id">{{
            account.name
          }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </app-sidebar-nav>
</div>

<app-dialog *ngIf="accountsWithInvites.length > 0 && allAccounts"
            [allowClose]="false"
            class="w-full"
            title="Account Invites">
  <ng-template>
  <div class="space-y-4">
    <h3>Please accept or decline the following invites:</h3>
    @for (ua of accountsWithInvites; track ua.id) {
      <div class="flex space-x-4 items-center">
        <div class="flex-1">Account: {{ getAccountName(ua.accountId) }}</div>
        <div class="space-x-4 flex flex-nowrap">
          <app-button type="accept" (click)="acceptInvite(ua.accountId)"></app-button>
          <app-button type="decline" (click)="declineInvite(ua.accountId)"></app-button>
        </div>
      </div>
    }
  </div>
  </ng-template>
</app-dialog>

<app-splash-screen *ngIf="loading"></app-splash-screen>


