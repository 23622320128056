import {Component, inject, Inject} from '@angular/core';
import {AuthService}               from '@auth0/auth0-angular';
import {DOCUMENT}                  from '@angular/common';
import {DatabaseService}           from 'src/app/core/services/database.service';
import {User}                      from "../../core/models/db/user";
import {SchoolYearService}         from "../../core/services/db/school-year.service";
import {DashboardService}          from "../../core/services/db/dashboard.service";
import {DashboardClass}            from "../../core/models/db/dashboard-class";
import {SchoolYear}                from "../../core/models/db/school-year";
import {parseDateLocal}            from "../../core/utilities";
import {filter}                    from "rxjs/operators";
import {ConfigService}             from "../../core/services/config.service";
import {takeUntilDestroyed}        from "@angular/core/rxjs-interop";
import {UserAssignment}            from "../../core/models/db/user-assignment";
import {AccountService}            from "../../core/services/db/account.service";
import {Weather}                   from "../../core/models/db/weather";
import {Today}                     from "../../core/models/db/dashboard/today";
import {ClazzSummary}              from "../../core/models/db/dashboard/class-summary";
import {StudentSummary}            from "../../core/models/db/dashboard/student-summary";
import {RouteGenService}           from "../../core/services/route-gen.service";
import {SetupProgress}             from "../../core/models/db/dashboard/setup-progress";
import {Account}                   from "../../core/models/db/account";
import * as moment                 from "moment-timezone";
import {take}                      from "rxjs";

@Component({
             selector: 'app-dashboard',
             templateUrl: './dashboard.component.html',
             styleUrls: ['./dashboard.component.scss'],
           })
export class DashboardComponent {
  classSummaries: DashboardClass[]                          = [];
  schoolYear: SchoolYear                                    = new SchoolYear();
  daysLeft                                                  = 0;
  user: User                                                = new User();
  uas: UserAssignment[]                                     = [];
  studentSummaries: StudentSummary[]                        = [];
  assignmentsToday                                          = 0;
  pastDueCount                                              = 0;
  isTodayLoading                                            = true;
  isSummaryLoading                                          = true;
  metadata: any;
  weatherData: Weather | undefined;
  showRescheduleDialog                                      = false;
  console                                                   = console;
  today: Today | null                                       = null;
  clazzSummaries: ClazzSummary[]                            = [];
  routeGenService                                           = inject(RouteGenService);
  noAccount                                                 = true;
  setupProgress: SetupProgress | null                       = null;
  account: Account | null                                   = null;
  timeZoneOptions: { displayText: string, value: string }[] = [];
  private schoolYearService                                 = inject(SchoolYearService);
  private dashboardService                                  = inject(DashboardService);
  private accountService                                    = inject(AccountService);

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public auth: AuthService,
    public db: DatabaseService
  ) {
    let usTimeZones = moment.tz.zonesForCountry('US');

    this.timeZoneOptions = usTimeZones.map(timeZone => {
      return {displayText: timeZone, value: timeZone};
    });

    ConfigService.accountId
      .pipe(
        takeUntilDestroyed(),
        filter(accountId => (accountId !== null && accountId > 0))
      )
      .subscribe((accountId) => {
        this.accountService.get(accountId!).then(account => {
          this.account = account;
          if (!this.account.isInitiallyReviewed) {
            let userTimeZone      = Intl.DateTimeFormat().resolvedOptions().timeZone;
            this.account.timeZone = String(this.timeZoneOptions.find(option => option.value === userTimeZone)!.value);
          }
        });
        this.setupProgress = null;
        this.noAccount     = false;
        this.refresh()
      });
  }

  get iAmAStudent(): boolean {
    return this.studentSummaries.find(ss => ss.name.id === this.user.id) !== undefined;
  }

  refresh() {
    this.weatherData = undefined;

    this.dashboardService.getSetupProgress(ConfigService.accountId.value!).then(progress => {
      this.setupProgress = progress;
    });

    this.dashboardService.getToday().then(today => {
      this.today          = today;
      this.isTodayLoading = false;
    });

    this.dashboardService.getStudentSummary().then(studentSummaries => {
      this.studentSummaries = studentSummaries;
    });

    this.accountService.getWeather().then(weather => {
      if (weather) {
        this.weatherData = weather;
      }
    });

    this.schoolYearService.getCurrent().then(schoolYear => {
      this.schoolYear = schoolYear;
      if (schoolYear == null) {
        this.daysLeft = 0;
      } else {
        this.daysLeft = Math.ceil((parseDateLocal(schoolYear.endDate).getTime() - Date.now()) / (1000 * 60 * 60 * 24));
        if (this.daysLeft < 0) {
          this.daysLeft = 0;
        }
      }
    });
  }

  ngOnInit() {
    this.db.getMyUser().subscribe(user => this.user = user);
    this.dashboardService.getClassSummary()
      .then(classes => {
              this.clazzSummaries   = classes;
              this.isSummaryLoading = false;
            },
            err => {
              this.isSummaryLoading = false
            }
      );
    this.dashboardService.getClasses()
      .then(classes => {
              this.classSummaries   = classes;
              this.isSummaryLoading = false;
            },
            err => {
              this.isSummaryLoading = false
            }
      );
  }

  check() {
    this.auth.getAccessTokenWithPopup().subscribe(res => console.log(JSON.stringify(res)));
  }

  login() {
    this.auth.loginWithRedirect().subscribe(res => {
      this.db.getMyUser().subscribe(user => this.user = user);
    });
  }

  closeCancel() {
    this.showRescheduleDialog = false;
    this.refresh()
  }

  accountReviewed() {
    if (this.account) {
      this.account.isInitiallyReviewed = true;
      this.accountService.update(this.account).pipe(
        take(1)
      ).subscribe(() => {
        ConfigService.accountId.next(ConfigService.accountId.value);
      });
    }
  }
}
