import {ChangeDetectorRef, Component, EventEmitter, inject, Inject, OnInit,} from '@angular/core';
import {DatabaseService}                                                     from '../../services/database.service';
import {ConfigService}                                                       from '../../services/config.service';
import {Router}                                                              from '@angular/router';
import {Account, AccountType}                                                from '../../models/db/account';
import {User}                                                                from '../../models/db/user';
import {AuthService}                                                         from '@auth0/auth0-angular';
import {DOCUMENT}                                                            from '@angular/common';
import {MediaMatcher}                                                        from '@angular/cdk/layout';
import {environment}                                                         from 'src/environments/environment';
import {AccountService}                                                      from '../../services/db/account.service';
import {AccessCode, PermissionValidationService}                             from "../../services/permission-validation.service";
import {UserAccount}                                                         from "../../models/db/user-account";
import {UserAccountService}                                                  from "../../services/db/user-account.service";
import {initFlowbite}                                                        from "flowbite";
import {take}                                                                from "rxjs";

@Component({
             selector: 'app-main',
             templateUrl: './main.component.html',
             styleUrls: ['./main.component.scss'],
           })
export class MainComponent implements OnInit {
  public boundAccountId: number        = -1;
  public allAccounts: Account[] | null = null;
  public displayedAccounts: Account[]  = [];
  public user: User | null             = null;
  permissionValidationService          = inject(PermissionValidationService);
  accountsWithInvites: UserAccount[]   = [];
  menuItems: any[]                     = [];
  toggleNavbar                         = new EventEmitter();
  mobileQuery: MediaQueryList;
  public nonProdEnvironment            = ''; // This is to display header in non-prod environment
  open                                 = false;
  loading                              = true;
  private userAccountService           = inject(UserAccountService);
  private _mobileQueryListener: () => void;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public auth: AuthService,
    private db: DatabaseService,
    private accountService: AccountService,
    public config: ConfigService,
    private router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
  ) {
    this.mobileQuery          = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    const path         = window.location.pathname;
    const segments     = path.split('/');
    const accountIndex = segments.indexOf('account');
    if (accountIndex !== -1 && accountIndex + 1 < segments.length) {
      const pathParameter = segments[accountIndex + 1];
      this.config.setSelectedAccountId(Number.parseInt(pathParameter));
    }
    this.setEmptyMenuItems();
  }

  async applyMenuPermissions(menuItems: any[]) {
    // IMPORTANT:
    // Security for new menu items should be added here and also in
    // the route guards
   await this.permissionValidationService.authorizePermissions(
      [AccessCode.ManagePlannedTimedOff]).then(result => {
      if (!result) {
        const filteredMenuItems = menuItems
          .filter(item => item.name !== "Time Off");
        menuItems               = JSON.parse(JSON.stringify(filteredMenuItems));
      }
    });

    await this.permissionValidationService.authorizePermissions(
      [AccessCode.ManageAccountUsers]).then(result => {
      if (!result) {
        const filteredMenuItems = menuItems
          .filter(item => item.name !== "Users");
        menuItems               = JSON.parse(JSON.stringify(filteredMenuItems));
      }
    });

    await this.permissionValidationService.authorizePermissions(
      [AccessCode.ManageAccountSettings,
        AccessCode.ManageAccountRoles]).then(result => {
      if (!result) {
        const filteredMenuItems = menuItems
          .filter(item => item.name !== "Settings");
        menuItems               = JSON.parse(JSON.stringify(filteredMenuItems));
      }
    });
    const accountType    = await this.config.getAccountType(this.boundAccountId);
    const accountTypeKey = accountType as unknown as keyof typeof AccountType;
    switch (AccountType[accountTypeKey]) {
      case AccountType.home:
        const filteredHomeMenuItems = menuItems.map(item => {
          if (item.name === 'Settings' && item.children) {
            item.children = item.children.filter((child: any) => child.name !== 'Security');
          }
          return item;
        });
        menuItems                   = JSON.parse(JSON.stringify(filteredHomeMenuItems));
        break;
      case AccountType.coop:
        const filteredCoopMenuItems = menuItems
          .filter(item => item.name !== "Transcripts" && item.name !== "Reporting");
        menuItems                   = JSON.parse(JSON.stringify(filteredCoopMenuItems));
        break;
    }

    await this.permissionValidationService.authorizePermission(AccessCode.ManageAccountSettings).then(result => {
      if (!result) {
        const filteredMenuItems = menuItems.map(item => {
          if (item.name === 'Settings' && item.children) {
            item.children = item.children.filter((child: any) => child.name !== 'Class Defaults' && child.name !== 'School Years');
          }
          return item;
        });
        menuItems               = JSON.parse(JSON.stringify(filteredMenuItems));
      }
    });

    return menuItems;
  }

  ngOnInit(): void {
    // Display the environment name in the header if not in production
    if (!environment.production) {
      this.document.title     = 'HSA - ' + environment.name;
      this.nonProdEnvironment = environment.name;
    }
    this.db.getMyUser().pipe(take(1)).subscribe((user) => {
                                                  this.user                  = user;
                                                  ConfigService.user         = user;
                                                  ConfigService.userAccounts = user.userAccounts;
                                                  this.accountsWithInvites   = user.userAccounts.filter(ua => ua.inviteSentDt && !ua.inviteAcceptedDt);
                                                  this.loadConfig();
                                                },
                                                (error) => {
                                                  this.loading = false;
                                                });
    ConfigService.accountId.subscribe((accountId) => {
      if (accountId) {
        this.boundAccountId = accountId;
        this.setMenuItems();
        if (this.displayedAccounts && this.displayedAccounts.length > 0) {
          this.accountService.get(accountId).then((account) => {
            this.displayedAccounts.find(a => a.id === accountId)!.name = account.name;
          });
        }
      }
    });
  }

  ngAfterViewInit() {
    initFlowbite();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  public setSelected() {
    if (this.boundAccountId > 0) {
      this.config.setSelectedAccountId(this.boundAccountId);
    }
    this.router.navigate(
      ConfigService.replacePathAccountId(this.boundAccountId)
    );
  }

  scheduleSupport() {
    window.open('https://calendly.com/homeschool-anchor/30-minute-live-support', '_blank');
  }

  openCanny() {
    window.open('https://homeschool-anchor.canny.io/feature-requests', '_blank');
  }

  public logout() {
    sessionStorage.clear();
    this.auth.logout({
                       logoutParams: {
                         returnTo: this.document.location.origin,
                       },
                     });
  }

  getAccountName(id: number): string {
    const account = this.allAccounts?.find(a => a.id === id);
    return account ? account.name : 'Account not found';
  }

  acceptInvite(accountId: number) {
    this.userAccountService.acceptInvite(accountId).then(() => {
      this.db.getMyUser().subscribe((user) => {
        ConfigService.userAccounts = user.userAccounts;
        this.accountsWithInvites   = user.userAccounts.filter(ua => ua.inviteSentDt && !ua.inviteAcceptedDt);
        if (this.accountsWithInvites.length == 0) {
          this.loadConfig();
        }
      });
    });
  }

  declineInvite(accountId: number) {
    this.userAccountService.declineInvite(accountId).then(() => {
      this.db.getMyUser().subscribe((user) => {
        ConfigService.userAccounts = user.userAccounts;
        this.accountsWithInvites   = user.userAccounts.filter(ua => ua.inviteSentDt && !ua.inviteAcceptedDt);
      });
    });
  }

  private async setEmptyMenuItems() {
    let menuItems  = [
      {
        name: 'Dashboard',
        icon: 'home',
        link: 'dashboard',
      },
      {
        divider: true,
        name: 'Account Settings'
      },
      {
        name: 'Accounts',
        icon: 'admin_panel_settings',
        link: 'accounts',
      },
    ];
    this.menuItems = JSON.parse(JSON.stringify(menuItems));
  }

  private async setMenuItems() {
    let menuItems = [
      {
        name: 'Dashboard',
        icon: 'home',
        link: 'dashboard',
      },
      {
        name: 'Calendar',
        icon: 'today',
        link: 'calendar',
      },
      {
        name: 'Assignments',
        icon: 'assignment',
        link: 'assignments',
      },
      {
        name: 'Classes',
        icon: 'school',
        link: ['account', this.boundAccountId, 'classes'],
      },
      {
        name: 'Time Off',
        icon: 'calendar_month',
        link: ['account', this.boundAccountId, 'time-off'],
      },
      {
        divider: true,
        name: 'Reporting'
      },
      {
        name: 'Transcripts',
        icon: 'grade',
        link: ['account', this.boundAccountId, 'transcripts'],
      },
      {
        divider: true,
        name: 'Account Settings'
      },
      {
        name: 'Settings',
        icon: 'settings',
        link: 'account/settings',
        children: [
          {
            name: 'Class Defaults',
            link: ['account', this.boundAccountId, 'settings', 'classes'],
          },
          {
            name: 'School Years',
            link: ['account', this.boundAccountId, 'settings', 'years'],
          },
          {
            name: 'Security',
            link: ['account', this.boundAccountId, 'settings', 'account-security'],
          }
        ],
      },
      {
        name: 'Accounts',
        icon: 'admin_panel_settings',
        link: 'accounts',
      },
      {
        name: 'Users',
        icon: 'group',
        link: ['account', this.boundAccountId, 'user'],
      }
    ];
    menuItems     = await this.applyMenuPermissions(menuItems);

    this.menuItems = JSON.parse(JSON.stringify(menuItems));
  }

  private async loadConfig() {
    // Set the initial selected account id
    let accountId = -1;
    if (sessionStorage.getItem('accountId')) {
      accountId = Number(sessionStorage.getItem('accountId'));
    }
    if (accountId > 0 && Number.isInteger(accountId)) {
      // Account id set in storage
      this.config.setSelectedAccountId(accountId);
    } else if (this.user?.defaultAccountId) {
      // Use the user's default account
      this.config.setSelectedAccountId(this.user.defaultAccountId);
    }

    this.accountService.getAccounts().then((accounts) => {
      this.allAccounts = accounts;
      accounts.forEach((account) =>
                         ConfigService.setAccountType(account.id, account.type)
      );

      this.displayedAccounts = accounts.filter(a => ConfigService._userAccounts
        .getValue()
        .find(ua => ua.accountId === a.id && ua.status === 'active'));
      if (this.displayedAccounts.length == 0) {
        // No accounts to display
        this.boundAccountId = -1;
        this.setEmptyMenuItems();
        this.config.setSelectedAccountId(-1);
      } else {
        // Accounts to display
        if (this.config.getSelectedAccountId()) {
          let account = this.displayedAccounts.find(a => a.id === this.config.getSelectedAccountId());
          if (account) {
            this.boundAccountId = account.id;
            this.setSelected();
          } else {
            this.boundAccountId = this.displayedAccounts[0].id;
            this.setSelected();
          }
        }
      }
      this.loading = false;
    });
  }
}
