<mat-card [style.background-color]="backgroundColor ? backgroundColor : null"
          [style.color]="textColor ? textColor : null"
          class="space-y-2 {{margin}}">
  <mat-card-header (click)="expanded = expandable ? !expanded : true;
                            expandedChange.emit(expanded);
                            $event.stopPropagation()"
                   *ngIf="title.length > 0">
    <mat-card-title>
      <div class="items-center flex gap-4 cursor-pointer">
        @if (thumbnailUrl && thumbnailUrl.length > 0) {
          <ngx-avatars borderColor="#c7c7c7"
                       [src]="thumbnailUrl"></ngx-avatars>
        }
        <span>
          {{ title }}
        </span>
        @if (helpUrl !== "") {
          <app-icon-button type="help" (click)="openHelpUrl()"></app-icon-button>
        }
        <md-icon-button
          *ngIf="expandable"
          aria-label="expand row">
          @if (expanded) {
            <md-icon>keyboard_arrow_up</md-icon>
          } @else {
            <md-icon>keyboard_arrow_down</md-icon>
          }
        </md-icon-button>
      </div>
    </mat-card-title>
    <mat-card-subtitle *ngIf="subtitle.length > 0">{{ subtitle }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div [@detailExpand]="expanded ? 'expanded' : 'collapsed'" class="gap-4 flex flex-col overflow-hidden">
      @if (isLoading) {
        <app-spinner></app-spinner>
      } @else {
        <ng-content></ng-content>
      }
    </div>
  </mat-card-content>
</mat-card>
