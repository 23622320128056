<div [class]="'-ml-4 ' + _class">
  @for (option of options; track option.label) {
    <div class="ml-4 gap-2 flex flex-1">
      <div>
        <md-radio [id]="option.label" name="group" [value]="option.value" [checked]="option.value==value"
                  [disabled]="disabled"
                  (change)="onValueChange($any($event.target).value)"></md-radio>
      </div>
      <div>
        <label [for]="option.label">{{ option.label }}</label>
        @if (option.helpText && option.helpText != "") {
          <md-button (click)="helpText = option.helpText; showHelp = !showHelp" class="mt-4">
            <md-icon>help</md-icon>
          </md-button>
        }
      </div>
    </div>
  }
</div>

<app-dialog (close)="showHelp=false" *ngIf="showHelp" [title]="'Help'">
  <ng-template>
  <div [innerHTML]="helpText"></div>
  </ng-template>
</app-dialog>
