import {booleanAttribute, Component, ElementRef, EventEmitter, inject, Input, Output} from '@angular/core';

@Component({
             selector: 'app-text-box',
             templateUrl: './text-box.component.html',
             styleUrl: './text-box.component.scss'
           })
export class TextBoxComponent {

  /**
   * The value of the text box. This can be any type of value.
   * If the type of the text box is 'time', this should be a string in the 'hh:mmZ' format.
   */
  @Input() value: any                            = '';
  @Input() label: string                         = '';
  @Output() valueChange                          = new EventEmitter<any>();
  @Input('class') class                          = '';
  //@HostBinding('style.display') display                                                                                  = 'inline-flex';
  @Input({transform: booleanAttribute}) required = false;
  @Input() error                                 = false;
  @Input() errorMessage                          = '';
  @Input() rows                                  = 5;
  @Input() readOnly                              = false;
  @Input() disabled                              = false;
  @Input() icon                                  = '';
  @Input() helpText                              = '';
  @Input() type: 'text' |
    'number' |
    'tel' |
    'search' |
    'email' |
    'url' |
    'password' |
    'textarea' |
    'time' |
    'localtime' |
    'percent'                                    = 'text';
  /**
   * This is fired when the text box loses focus and the value has changed.  If the value hasn't changed, then this isn't fired.
   */
  @Output() blur                                 = new EventEmitter<any>();
  showHelp                                       = false;
  _value: any                                    = '';
  console                                        = console;
  protected _type                                = this.type;
  private el                                     = inject(ElementRef);
  private previousValue                          = undefined;

  ngAfterViewInit() {
    const classes = this.el.nativeElement.classList;
    classes.forEach((className: string) => {
      //this.renderer.removeClass(this.el.nativeElement, className);
    });
  }

  ngOnChanges(object: any) {
    if (object.type) {
      if (this.type === 'localtime') {
        this._type = 'time';
      } else if (this.type === 'percent') {
        this._type = 'number';
      } else {
        this._type = this.type;
      }
    }
    if (object.value) {
      if (this.type === "time" || this.type === "localtime") {
        if (!this.value || this.value === '') {
          this._value = '';
        } else if (this.type === "time") {
          // Convert the UTC time to local
          //let date    = new Date(`1970-01-01T${this.value}`);
          let date = new Date(`${new Date().toISOString().slice(0, 10)}T${this.value}`);

          let hours   = date.getHours().toString().padStart(2, '0');
          let minutes = date.getMinutes().toString().padStart(2, '0');
          this._value = `${hours}:${minutes}`;
        } else if (this.type === "localtime") {
          let date    = new Date(`${new Date().toISOString().slice(0, 10)}T${this.value}`);
          let hours   = date.getHours().toString().padStart(2, '0');
          let minutes = date.getMinutes().toString().padStart(2, '0');
          this._value = `${hours}:${minutes}`;
        }
      } else if (this.type == 'percent') {
        if (this.value == null || this.value === '') {
          this._value = '';
        } else {
          let decimalPlaces = (this._value.toString().split('.')[1] || []).length;
          this._value       = parseFloat((this.value * 100).toFixed(2 + decimalPlaces));
        }
      } else {
        if (this.value == null || this.value === '') {
          this._value = '';
        } else {
          this._value = this.value;
        }
      }
    }
    if (this.previousValue === undefined) {
      // We only want to set this the first time
      this.previousValue = this._value;
    }
  }

  onValueChange(value: any) {
    if (this.type === "time" || this.type === "localtime") {
      if (value === '') {
        this.valueChange.emit('');
      } else if (this.type === "time") {
        // Convert the time to UTC and emit it back
        let [hours, minutes] = value.split(':');
        let date             = new Date();
        date.setHours(parseInt(hours), parseInt(minutes));
        let utcHours   = date.getUTCHours().toString().padStart(2, '0');
        let utcMinutes = date.getUTCMinutes().toString().padStart(2, '0');
        this.valueChange.emit(`${utcHours}:${utcMinutes}Z`);
      } else if (this.type === "localtime") {
        let [hours, minutes] = value.split(':');
        let date             = new Date();
        date.setHours(parseInt(hours), parseInt(minutes), 0, 0);
        hours   = date.getHours().toString().padStart(2, '0');
        minutes = date.getMinutes().toString().padStart(2, '0');
        this.valueChange.emit(`${hours}:${minutes}`);
      }
    } else if (this.type == 'percent') {
      if (value == null || value === '') {
        this.valueChange.emit(null);
      } else {
        // Calculate the number of decimal places in the entered value
        let decimalPlaces = (value.toString().split('.')[1] || []).length;

        // Use the calculated number of decimal places in the toFixed() method
        // This is a percentage so a whole number is 2 decimals (ie 8 = .08) so add 2 to whatever they entered
        // ie. 8.25 = 0.0825 (4 decimals)
        this.valueChange.emit(parseFloat((value / 100).toFixed(2 + decimalPlaces)));
      }
    } else {
      if (value === '' && this.type === 'number') {
        this.valueChange.emit(null);
      } else {
        this.valueChange.emit(value);
      }
    }
  }

  onBlurEvent() {
    /**
     * We want to fire this only after a complete change on the box, not for each letter for character
     */
    if (this.type == 'percent') { // We don't have this used right now, so it may or may not work?
      if (this.value === null || this.value === '') {
        this.value = null;
      } else {
        // Calculate the number of decimal places in the entered value
        let decimalPlaces = (this.value.toString().split('.')[1] || []).length;

        // Use the calculated number of decimal places in the toFixed() method
        this.value = parseFloat((this.value / 100).toFixed(2 + decimalPlaces));
      }
    }
    if (this.previousValue !== this._value) {
      this.previousValue = this._value; // Save the new value for the next comparison;
      setTimeout(() => {
        this.blur.emit(this.value);
      }, 1);
    }
  }
}
