import {Component, EventEmitter, inject, Input, Output, SimpleChanges} from '@angular/core';
import {takeUntilDestroyed}                                            from '@angular/core/rxjs-interop';
import {Account}                                                       from 'src/app/core/models/db/account';
import {ConfigService}                                                 from 'src/app/core/services/config.service';
import {ConfirmationService}                                           from 'src/app/core/services/confirmation.service';
import {AccountService}                                                from 'src/app/core/services/db/account.service';
import {NotificationService}                                           from 'src/app/core/services/notification.service';
import {Location}                                                      from '@angular/common';
import {RadioOption}                                                   from "../../../shared/radio/radio.component";
import {SelectOption}                                                  from "../../../shared/select/select.component";
import * as moment                                                     from "moment-timezone";
import {PaymentService}                                                from "../../../core/services/db/payment.service";

declare var Chargebee: any;

@Component({
             selector: 'app-account-detail',
             templateUrl: './account-detail.component.html',
             styleUrl: './account-detail.component.scss'
           })
export class AccountDetailComponent {
  @Input() accountId: number           = -1;
  @Output() updated: EventEmitter<any> = new EventEmitter();
  public account: Account              = new Account();

  radioOptions: RadioOption[]               = [
    {label: 'Homeschool', value: 'home'},
    {label: 'Co-op', value: 'coop'}
  ]
  showSubscriptionDialog = false;
  protected timeZoneOptions: SelectOption[] = [];
  private paymentService                    = inject(PaymentService);

  constructor(private accountService: AccountService, private notificationService: NotificationService,
              private confirm: ConfirmationService, private _location: Location) {

    // Get an array of US timezones
    let usTimeZones = moment.tz.zonesForCountry('US');

    this.timeZoneOptions = usTimeZones.map(timeZone => {
      return {displayText: timeZone, value: timeZone};
    });

    if (_location.path().endsWith('/new')) {
      let userTimeZone              = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.account.timeZone         = String(this.timeZoneOptions.find(option => option.value === userTimeZone)!.value);
      this.account.facilitator      = ConfigService.user.firstName + ' ' + ConfigService.user.lastName;
      this.account.facilitatorTitle = "Administrator";
      this.account.email            = ConfigService.user.email;
    } else {
      ConfigService.accountId
        .pipe(takeUntilDestroyed())
        .subscribe((accountId) => {
          if (accountId) {
            this.accountId = accountId;
            this.refresh();
          }
        });
    }
  }

  ngAfterViewInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['accountId']) {
      this.refresh();
    }
  }

  refresh() {
    if (this.accountId && this.accountId != -1) {
      this.accountService.get(this.accountId).then(account => {
        this.account = account;
      });
    } else {
      this.account = new Account();
    }
  }

  async managePayments() {
    const cbInstance = Chargebee.getInstance();
    cbInstance.setPortalSession(() => this.paymentService.getPortalUrl(this.account.id));
    let cbPortal = cbInstance.createChargebeePortal();
    cbPortal.open({
                    close() {
                      //close callbacks
                      cbInstance.logout();
                    }
                  });
  }

  showSubscription() {
    this.paymentService.getPlans(this.accountId).then(plans => {
      console.log(plans);
    });
    this.showSubscriptionDialog = true;
  }

  subscribe() {
    const cbInstance = Chargebee.getInstance();
    cbInstance.openCheckout({
                              hostedPage: () => this.paymentService.subscribe(this.account.id, 'Homeschool'),
                              success: () => {
                                this.notificationService.success('Subscription successful');
                                this.refresh();
                              }
                            }
    );
  }

  save() {
    if (this.account.id > 0) {
      this.accountService.update(this.account).subscribe(() => {
        this.updated.emit();
        this.notificationService.success('Account updated');
        this._location.back();
      });
    } else {
      this.accountService.create(this.account).subscribe((acccount) => {
        this.account = acccount;
        this.updated.emit();
        this.notificationService.success('Account created');
        this._location.back();
      });
    }
  }

  /**
   * This is not currently in use as we are not allowing accounts to be deleted
   *  To enable, add this to the app-page element
   *  [delete]="accountId < -1 ? delete.bind(this) : null"
   *
   */
  delete() {
    if (this.account.id > 0) {
      this.confirm.confirm(
        'Delete Account',
        'Are you sure you want to delete this account?',
        'Delete',
        'Cancel',
        () => {
          this.accountService
            .delete(this.account.id)
            .subscribe(() => this._location.back());
        },
        () => {
        }
      );
    }
  }
}
