import {HttpClient}                                                     from '@angular/common/http';
import {Component, HostBinding, HostListener, inject, Input, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent}                                        from '@angular/material/paginator';
import {MatTableDataSource}                                             from '@angular/material/table';
import {Observable, of, Subscription,}                                  from 'rxjs';
import {DatePipe, DecimalPipe}                                          from '@angular/common';
import {Router}                                                         from '@angular/router';
import {TableColumn}                                                    from "../../core/models/ui/table-column";
import {BreakpointObserver}                                             from "@angular/cdk/layout";
import {MatSort, Sort}                                                  from "@angular/material/sort";
import {Name}                                                           from "../../core/models/db/name";


@Component({
             selector: 'app-table',
             templateUrl: './table.component.html',
             styleUrl: './table.component.scss'
           })
export class TableComponent {
  @Input() enableSearch                         = true;
  @Input() refresh: Observable<void> | null     = null;
  @Input() pageSize: number                     = 10;
  @Input() pageSizeOptions: number[]            = [10, 20, 30, 50];
  @Input() headers: TableColumn[]               = [];
  @Input() sort: (([]: any) => any[]) | null    = null;
  @HostBinding('style.display') display         = 'block';
  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) _sort!: MatSort;
  public dataSource                             = new MatTableDataSource<any>();
  public displayedColumns: string[]             = [];
  resultsLength                                 = 0;
  isLoadingResults                              = true;
  screenSize: 'sm' | 'md' | 'lg' | 'xl' | '2xl' = 'lg';
  console                                       = console
  hidePaginator                                 = false;
  isTableRendering                              = false;
  shownName: Name | null                        = null;
  protected readonly Name                       = Name;
  private subscription: Subscription | undefined;
  private datePipe                              = inject(DatePipe);
  private decimalPipe                           = inject(DecimalPipe);
  private refreshSubscription: Subscription | undefined;

  constructor(private _httpClient: HttpClient, private router: Router, private breakpointObserver: BreakpointObserver) {

    //if (this.breakpointObserver.isMatched(Breakpoints.XSmall) || this.breakpointObserver.isMatched(Breakpoints.Small)) {
    //  this.screenSize = 'sm';
    //} else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
    //  this.screenSize = 'md';
    //} else if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
    //  this.screenSize = 'lg';
    //} else {
    //  this.screenSize = 'xl';
    //}

    if (this.breakpointObserver.isMatched('(max-width: 800px)')) {
      this.screenSize = 'sm';
    } else if (this.breakpointObserver.isMatched('(max-width: 1023px)')) {
      this.screenSize = 'md';
    } else if (this.breakpointObserver.isMatched('(max-width: 1279px)')) {
      this.screenSize = 'lg';
    } else if (this.breakpointObserver.isMatched('(max-width: 1535px)')) {
      this.screenSize = 'xl';
    } else {
      this.screenSize = '2xl';
    }

    this.breakpointObserver
      .observe(['(max-width: 767px)', '(max-width: 1023px)', '(max-width: 1279px)', '(max-width: 1535px)', '(max-width: 1536px)'])
      .subscribe(result => {
        const breakpoints = result.breakpoints;
        if (breakpoints['(max-width: 767px)']) {
          this.screenSize = 'sm';
        } else if (breakpoints['(max-width: 1023px)']) {
          this.screenSize = 'md';
        } else if (breakpoints['(max-width: 1279px)']) {
          this.screenSize = 'lg';
        } else if (breakpoints['(max-width: 1535px)']) {
          this.screenSize = 'xl';
        } else {
          this.screenSize = '2xl';
        }
        this.displayedColumns = this.headers
          .filter(header => this.columnShouldBeShown(header))
          .map(h => h.columnDef);
      });

  }

  trackTask(index: number, item: any): string {
    return '' + index;
  }

  onPageEvent(event: PageEvent) {
    this.isTableRendering = true;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    this.shownName = null;
  }

  ngAfterViewChecked() {
    if (this.isTableRendering) {
      this.isTableRendering = false;
    }
  }

  /**
   * This is the function that will be called to get the data
   * If a result has a key 'tableRowColor', it will be used to color the row with the value
   */
  @Input() get: () => Observable<any[]> = () => {
    return of([]);
  };

  ngOnChanges(changes: any) {
    if (changes.headers) {
      this.displayedColumns = this.headers
        .filter(header => this.columnShouldBeShown(header))
        .map(h => h.columnDef);
      this.setDefaultPipes();
    }
  }

  setDefaultPipes() {
    this.headers.filter(h => !h.pipe).forEach(header => {
      switch (header.type) {
        case 'number':
          header.pipe = (value: any) => this.decimalPipe.transform(value, '1.0-0');
          break;
        case 'decimal':
          header.pipe = (value: any) => this.decimalPipe.transform(value, '1.2-2');
          break;
        case 'date':
          header.pipe = (value: any) => this.datePipe.transform(value, 'mediumDate');
          break;
        case 'dateTime':
          header.pipe = (value: any) => this.datePipe.transform(value, 'medium');
          break;
        case 'time':
          header.pipe = (value: any) => this.datePipe.transform(value, 'shortTime');
          break;
        default:
          header.pipe = (value: any) => value;
      }
    });
  }

  ngOnInit() {
    this.displayedColumns = this.headers
      .filter(header => this.columnShouldBeShown(header))
      .map(h => h.columnDef);
    this.setDefaultPipes();

    if (this.refresh) {
      this.refreshSubscription = this.refresh.subscribe(() => {
        this.refreshData();
      });
    } else {
      this.refreshData();
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.refreshSubscription?.unsubscribe();
  }

  constructUrl(template: string, value: string): string {
    return template.replace('{value}', value);
  }

  handleLinkClick(template: string, value: string, event: MouseEvent): void {
    if (template.startsWith('/')) {
      event.preventDefault(); // Prevent default anchor behavior
      const url = this.constructUrl(template, value);
      this.router.navigate([url]); // Perform internal navigation
    }
    // For external links, the default behavior will occur
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort      = this._sort;
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      //console.log(`Sorted ${sortState.direction}ending`);
    } else {
      //console.log('Sorting cleared');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async isVisible(header: TableColumn, element: any): Promise<boolean> {
    return Promise.resolve(true);
    //if (header.visible == null) {
    //  return true;
    //} else {
    //  //return await header.visible(element);
    //}
  }

  private columnShouldBeShown(header: TableColumn): boolean {
    let show = !header.minSize ||
      (header.minSize === 'md' && (this.screenSize == 'md' || this.screenSize == 'lg' || this.screenSize == 'xl' || this.screenSize == '2xl')) ||
      (header.minSize === 'lg' && (this.screenSize == 'lg' || this.screenSize == 'xl' || this.screenSize == '2xl')) ||
      (header.minSize === 'xl' && (this.screenSize == 'xl' || this.screenSize == '2xl')) ||
      (header.minSize === '2xl' && (this.screenSize == '2xl'));

    show = show && (!header.maxSize ||
      (header.maxSize === 'sm' && (this.screenSize == 'sm')) ||
      (header.maxSize === 'md' && (this.screenSize == 'md' || this.screenSize == 'sm')) ||
      (header.maxSize === 'lg' && (this.screenSize == 'lg' || this.screenSize == 'md' || this.screenSize == 'sm')) ||
      (header.maxSize === 'xl' && (this.screenSize == 'xl' || this.screenSize == 'lg' || this.screenSize == 'md' || this.screenSize == 'sm')) ||
      (header.maxSize === '2xl' && (this.screenSize == '2xl' || this.screenSize == 'xl' || this.screenSize == 'lg' || this.screenSize == 'md' || this.screenSize == 'sm')));
    return show;
  }

  private refreshData() {
    // Wrap these in promises in case get() is syncronous which would then change a bound variable during the wrong
    // component lifecycle phase
    this.isLoadingResults = true;
    this.dataSource.data  = [];
    this.resultsLength    = 0;
    this.hidePaginator    = true;

    this.subscription?.unsubscribe();
    this.subscription = this.get().subscribe(
      (data: any[]) => {
        Promise.resolve().then(() => {
          if (this.sort) {
            data = this.sort(data);
          }
          this.dataSource.data = data;
          this.resultsLength   = data.length;
          this.hidePaginator   = this.resultsLength <= this.pageSize;
        })
      },
      () => {
        Promise.resolve().then(() => {
          this.hidePaginator = true;
        });
      },
      () => {
        Promise.resolve().then(() => {
          this.isLoadingResults = false;
        });
      });
  }
}
