<div class="flex flex-nowrap gap-2">
  <mat-form-field [class]="class">
    <mat-label *ngIf="label && label.length>0">{{ label }}</mat-label>
    <mat-select (valueChange)="onValueChange($event)"
                [disabled]="disabled"
                [formControl]="selectControl"
                [placeholder]="label"
                [required]="required"
                [value]='value'>
      @for (option of options; track option.value) {
        <mat-option [value]="option.value">{{ option.displayText }}</mat-option>
      }
    </mat-select>
    <mat-hint *ngIf="required">* This field is required</mat-hint>
    @if (selectControl.hasError('required')) {
      <mat-error>{{ label }} is required</mat-error>
    }
  </mat-form-field>
  @if (helpText != "") {
    <md-button (click)="showHelp = !showHelp" class="mt-4">
      <md-icon>help</md-icon>
    </md-button>
  }
</div>
<app-dialog (close)="showHelp=false" *ngIf="showHelp" [title]="label">
  <ng-template>
    <div [innerHTML]="helpText"></div>
  </ng-template>
</app-dialog>
