import {CUSTOM_ELEMENTS_SCHEMA, NgModule}                               from '@angular/core';
import {CommonModule}                                                   from '@angular/common';
import {CheckboxComponent}                                              from "./checkbox/checkbox.component";
import {RadioComponent}                                                 from "./radio/radio.component";
import {SwitchComponent}                                                from "./switch/switch.component";
import {TextBoxComponent}                                               from "./text-box/text-box.component";
import {DateBoxComponent}                                               from "./date-box/date-box.component";
import {CardHeaderComponent}                                            from "./card-header/card-header.component";
import {TableComponent}                                                 from "./table/table.component";
import {PageComponent}                                                  from "./page/page.component";
import {SidebarNavComponent}                                            from "./sidebar-nav/sidebar-nav.component";
import {ButtonComponent}                                                from "./button/button.component";
import {CalloutComponent}                                               from "./callout/callout.component";
import {DialogComponent}                                                from "./dialog/dialog.component";
import {ListItemsComponent}                                             from "./list-items/list-items.component";
import {MatHint, MatInputModule}                                        from "@angular/material/input";
import {MatTableModule}                                                 from "@angular/material/table";
import {MatListModule}                                                  from "@angular/material/list";
import {MatDatepickerModule}                                            from "@angular/material/datepicker";
import {MatCardModule}                                                  from "@angular/material/card";
import {MatButtonModule}                                                from "@angular/material/button";
import {MatSnackBarModule}                                              from "@angular/material/snack-bar";
import {MatFormFieldModule}                                             from "@angular/material/form-field";
import {MatSelectModule}                                                from "@angular/material/select";
import {MatSidenavModule}                                               from "@angular/material/sidenav";
import {MatToolbarModule}                                               from "@angular/material/toolbar";
import {MatIconModule}                                                  from "@angular/material/icon";
import {MatMenuModule}                                                  from "@angular/material/menu";
import {MatNativeDateModule}                                            from "@angular/material/core";
import {MatCheckboxModule}                                              from "@angular/material/checkbox";
import {MatExpansionModule}                                             from "@angular/material/expansion";
import '@material/web/switch/switch'
import '@material/web/icon/icon';
import '@material/web/iconbutton/icon-button';
import '@material/web/iconbutton/filled-icon-button';
import '@material/web/iconbutton/filled-tonal-icon-button';
import '@material/web/button/text-button';
import '@material/web/button/filled-tonal-button';
import '@material/web/button/outlined-button';
import '@material/web/button/elevated-button';
import '@material/web/button/filled-button';
import '@material/web/textfield/outlined-text-field';
import '@material/web/select/outlined-select';
import '@material/web/chips/filter-chip';
import '@material/web/list/list';
import '@material/web/list/list-item';
import '@material/web/checkbox/checkbox';
import '@material/web/radio/radio';
import '@material/web/divider/divider';
import {MatPaginatorModule}                                             from "@angular/material/paginator";
import {RouterModule}                                                   from '@angular/router';
import {FormsModule, ReactiveFormsModule}                               from "@angular/forms";
import {SelectComponent}                                                from './select/select.component';
import {TimeBoxComponent}                                               from './time-box/time-box.component';
import {ChipInputSetComponent}                                          from './chip-input-set/chip-input-set.component';
import {MatChipGrid, MatChipInput, MatChipRow, MatChipsModule}          from "@angular/material/chips";
import {MatAutocomplete, MatAutocompleteModule, MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {BlurDirective}                                                  from "../core/directives/blur.directive";
import {MatSort, MatSortModule}                                         from "@angular/material/sort";
import {MatProgressSpinner}                                             from "@angular/material/progress-spinner";
import {SpinnerComponent}                                               from './spinner/spinner.component';
import {InternalLinkComponent}                                          from './internal-link/internal-link.component';
import {AvatarModule}                                                   from "ngx-avatars";
import {NamePipe}                                                       from "../core/pipes/name.pipe";
import {UserCardComponent}                                              from './user-card/user-card.component';
import {CheckContentDirective}                                          from "../core/directives/check-content.directive";
import {IconButtonComponent}                                            from './icon-button/icon-button.component';
import {ColorPickerComponent}                                           from './color-picker/color-picker.component';
import {NgxColorsModule}                                                from "ngx-colors";
import {MatDialogComponent}                                             from './mat-dialog/mat-dialog.component';
import {MatDialogModule}                                                from "@angular/material/dialog";
import {ProfilePictureComponent}                                        from './profile-picture/profile-picture.component';
import {DialogIframeComponent}                                          from './dialog-iframe/dialog-iframe.component';
import {HelpLinkComponent}                                              from './help-link/help-link.component';
import { FeatureRequestComponent } from './feature-request/feature-request.component';

@NgModule({
            schemas: [CUSTOM_ELEMENTS_SCHEMA],
            declarations: [
              ListItemsComponent,
              CardHeaderComponent,
              TableComponent,
              PageComponent,
              SidebarNavComponent,
              ButtonComponent,
              CalloutComponent,
              DialogComponent,
              TextBoxComponent,
              DateBoxComponent,
              SwitchComponent,
              CheckboxComponent,
              RadioComponent,
              SelectComponent,
              TimeBoxComponent,
              ChipInputSetComponent,
              BlurDirective,
              SpinnerComponent,
              InternalLinkComponent,
              UserCardComponent,
              CheckContentDirective,
              IconButtonComponent,
              ColorPickerComponent,
              MatDialogComponent,
              ProfilePictureComponent,
              DialogIframeComponent,
              HelpLinkComponent,
              FeatureRequestComponent,
            ],
            imports: [
              ReactiveFormsModule,
              FormsModule,
              RouterModule,
              MatCardModule,
              MatButtonModule,
              MatSnackBarModule,
              MatFormFieldModule,
              MatSelectModule,
              MatSidenavModule,
              MatToolbarModule,
              MatIconModule,
              MatMenuModule,
              MatNativeDateModule,
              MatCheckboxModule,
              MatExpansionModule,
              MatHint,
              MatInputModule,
              CommonModule,
              MatTableModule,
              MatListModule,
              MatDatepickerModule,
              MatPaginatorModule,
              MatChipInput,
              MatAutocompleteTrigger,
              MatAutocomplete,
              MatChipGrid,
              MatChipRow,
              MatAutocompleteModule,
              MatChipsModule,
              MatSort,
              MatSortModule,
              MatProgressSpinner,
              AvatarModule,
              NamePipe,
              NgxColorsModule,
              MatButtonModule,
              MatDialogModule
            ],
            exports: [
              ListItemsComponent,
              CardHeaderComponent,
              TableComponent,
              PageComponent,
              SidebarNavComponent,
              ButtonComponent,
              CalloutComponent,
              DialogComponent,
              TextBoxComponent,
              DateBoxComponent,
              SwitchComponent,
              CheckboxComponent,
              RadioComponent,
              SelectComponent,
              ChipInputSetComponent,
              SpinnerComponent,
              InternalLinkComponent,
              UserCardComponent,
              TimeBoxComponent,
              IconButtonComponent,
              ColorPickerComponent,
              ProfilePictureComponent,
              DialogIframeComponent,
              HelpLinkComponent,
              FeatureRequestComponent,
            ],
          })
export class SharedModule {
}
