import { Component } from '@angular/core';

@Component({
  selector: 'app-feature-request',
  templateUrl: './feature-request.component.html',
  styleUrl: './feature-request.component.scss'
})
export class FeatureRequestComponent {

}
