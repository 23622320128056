import {inject, Injectable}      from '@angular/core';
import {HttpClient}              from "@angular/common/http";
import {ConfigService}           from "../config.service";
import {UserAssignment}          from "../../models/db/user-assignment";
import {lastValueFrom}           from "rxjs";
import {UserAssignmentCompleted} from "../../models/db/user-assignment-completed";
import {UserAssignmentGraded}    from "../../models/db/user-assignment-graded";
import {SingleValue}             from "../../models/db/single-value";
import {DashboardClass}          from "../../models/db/dashboard-class";
import {Today}                   from "../../models/db/dashboard/today";
import {ClazzSummary}            from "../../models/db/dashboard/class-summary";
import {StudentSummary}          from "../../models/db/dashboard/student-summary";
import {SetupProgress}           from "../../models/db/dashboard/setup-progress";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  protected http = inject(HttpClient);

  get url(): string {
    return '/api/dashboard';
  }
  constructor() { }

  public async getClasses(): Promise<DashboardClass[]> {
    const response = this.http.get<DashboardClass[]>(this.url+'/classes');
    return await lastValueFrom(response);
  }

  public async getToday(): Promise<Today> {
    const response = this.http.get<Today>(this.url+'/today');
    return await lastValueFrom(response);
  }

  public async getClassSummary(): Promise<ClazzSummary[]> {
    const response = this.http.get<ClazzSummary[]>(this.url+'/classSummary');
    return await lastValueFrom(response);
  }

  public async getStudentSummary(): Promise<StudentSummary[]> {
    const response = this.http.get<StudentSummary[]>(this.url+'/studentSummary');
    return await lastValueFrom(response);
  }

  public async getSetupProgress(accountId: number | null): Promise<SetupProgress> {
    if (accountId == null){
      return {classes: false, schoolYears: false, students: false, timeOff: false};
    }
    const response = this.http.get<SetupProgress>(this.url+'/' + accountId + '/setupProgress');
    return await lastValueFrom(response);
  }
}
