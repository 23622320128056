<app-text-box (valueChange)="applyFilter($event)" *ngIf="enableSearch" class="mb-4 w-full" icon="search" label="Search"
              type="text"></app-text-box>
<div class="example-container mat-elevation-z8 block">
  <div class="overflow-auto">
    <table (matSortChange)="announceSortChange($event)"
           [dataSource]="dataSource"
           [trackBy]="trackTask"
           mat-table
           matSort>
      <ng-container *ngFor="let header of headers">
        <ng-container [matColumnDef]="header.columnDef">
          <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by">{{ header.header }}</th>
          <td *matCellDef="let element"
              [style.background-color]="element.tableRowColor || 'initial'"
              [style.padding-bottom]="'0.5rem'"
              [style.padding-top]="'0.5rem'"
              [style]="header.style"
              mat-cell>
            @switch (header.type) {
              @case ('boolean') {
                @if (header.editable ? header.editable(element) : false) {
                  <app-checkbox [value]="element[header.columnDef]"
                                (valueChange)="element[header.columnDef]=$event;header.changed ? header.changed(element):{}"
                                [disabled]="header.disabled ? header.disabled(element):false"></app-checkbox>
                } @else {
                  @if (element[header.columnDef]) {
                    Yes
                  } @else {
                    No
                  }
                }
              }
              @case ('number') {
                @if (header.editable ? header.editable(element) : false) {
                  <app-text-box [type]="'number'"
                                [(value)]="element[header.columnDef]"
                                (blur)="header.changed ? header.changed(element):{}"></app-text-box>
                } @else {
                  {{ header.pipe!(element[header.columnDef]) }}
                }
              }
              @case ('percent') {
                {{ element[header.columnDef] | percent }}
              }
              @case ('decimal') {
                {{ header.pipe!(element[header.columnDef]) }}
              }
              @case ('date') {
                @if (header.editable ? header.editable(element) : false) {
                  <app-date-box [(value)]="element[header.columnDef]"
                                (valueChange)="header.changed ? header.changed(element):{}"></app-date-box>
                } @else {
                  {{ header.pipe!(element[header.columnDef]) }}
                }
              }
              @case ('dateTime') {
                {{ header.pipe!(element[header.columnDef]) }}
              }
              @case ('time') {
                {{ header.pipe!(element[header.columnDef]) }}
              }
              @case ('button') {
                <app-button [type]="header.buttonType" [text]="header.header"
                            (click)="header.changed ? header.changed(element):{}"></app-button>
              }
              @case ('buttons') {
                <div class="flex gap-4">
                  @for (button of header.buttons; track $index) {
                    <app-button [type]="button.type" [text]="button.label?button.label:''"
                                (click)="button.clicked(element)"
                                [iconOnly]="true"
                                [ngClass]="{'hidden': button.hidden && button.hidden(element)}"></app-button>
                  }
                </div>
              }
              @case ('avatars') {
                <div *ngIf="element[header.columnDef] && element[header.columnDef].length > 0"
                     class="flex flex-nowrap ml-4 cursor-pointer">
                  @for (name of element[header.columnDef]; track name.id) {
                    <div (click)="shownName = name;$event.stopPropagation();" class="-ml-4">
                      <ngx-avatars [name]="name | name"
                                   [title]="name | name" class="drop-shadow-md"
                                   borderColor="#c7c7c7"
                                   [bgColor]="name.color"
                                   [src]="name.thumbnailUrl"></ngx-avatars>
                      <app-user-card *ngIf="shownName!==null && shownName === name"
                                     class="absolute"
                                     [name]="shownName"
                                     (close)="shownName=null">{{ name | name }}
                      </app-user-card>
                    </div>
                  }
                </div>
              }
              @case ('url') {
                <a [href]="element[header.columnDef]" target="_blank">{{ element[header.columnDef] }}</a>
              }
              @case ('link') {
                <md-filled-icon-button
                  *ngIf="header.urlTemplate && header.urlValueColumn && header.icon"
                  (click)="handleLinkClick(header.urlTemplate,element[header.urlValueColumn],$event)">
                  <md-icon>{{ header.icon }}</md-icon>
                </md-filled-icon-button>
                <a *ngIf="header.urlTemplate && header.urlValueColumn && !header.icon"
                   href="{{constructUrl( header.urlTemplate, element[header.urlValueColumn])}}"
                   (click)="handleLinkClick(header.urlTemplate,element[header.urlValueColumn],$event)">
                  {{ header.pipe!(element[header.columnDef]) }}
                </a>
              }
              @case ('textarea') {
                @if (header.editable ? header.editable(element) : false) {
                  <app-text-box [(value)]="element[header.columnDef]" type="textarea" [rows]="3" class="w-full"
                                (blur)="header.changed ? header.changed(element):{}"></app-text-box>
                } @else {
                  {{ element[header.columnDef] }}
                }
              }
              @case ('select') {
                @if (header.options && header.options.length > 0) {
                  <app-select [disabled]="header.editable ? !header.editable(element):true"
                              [options]="header.options"
                              [value]="element[header.columnDef]"
                              (valueChange)="element[header.columnDef] = $event; header.changed ? header.changed(element):{}"></app-select>
                } @else {
                  {{console.log(header.options)}}
                }
              }
              @case ('text') {
                @if (header.editable ? header.editable(element) : false) {
                  <app-text-box [(value)]="element[header.columnDef]" type="text"
                                (blur)="header.changed ? header.changed(element):{}"></app-text-box>
                } @else if (element[header.columnDef]) {
                  <ng-container *ngFor="let line of header.pipe!(element[header.columnDef]).split('\n')">
                    {{ line }}<br/>
                  </ng-container>
                }
              }
              @default {
                {{ element[header.columnDef] }}
              }
            }
          </td>
        </ng-container>
      </ng-container>

      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
    </table>
  </div>
  <mat-paginator (page)="onPageEvent($event)"
                 [hidden]="hidePaginator"
                 [length]="resultsLength"
                 [pageSizeOptions]="pageSizeOptions"
                 [pageSize]="pageSize">
  </mat-paginator>
  <app-spinner *ngIf="isLoadingResults || isTableRendering">
  </app-spinner>
</div>
