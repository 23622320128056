import { Injectable } from '@angular/core';
import {ConfigService} from "./config.service";

@Injectable({
  providedIn: 'root'
})
export class RouteGenService {

  constructor() { }

  getAccount(): any[] {
    return ['/', 'account', ConfigService.accountId.getValue(), 'details'];
  }
  getUser(userId: number): any[] {
    return ['/', 'account', ConfigService.accountId.getValue(), 'user', userId];
  }
  getClass(classId: number):any[]{
    return ['/', 'account', ConfigService.accountId.getValue(), 'classes', classId];
  }
  getClassTemplate(){
    return ['/', 'account', ConfigService.accountId.getValue(), 'classes', '{value}'];
  }
  getClassGradesTemplate(){
    return ['/', 'account', ConfigService.accountId.getValue(), 'classes', '{value}','grades'];
  }
  getTranscript(studentId: number): any[] {
    return ['/', 'account', ConfigService.accountId.getValue(), 'transcripts', studentId];
  }
  getClassDefaults(){
    return ['/', 'account', ConfigService.accountId.getValue(), 'settings', 'classes'];
  }
  getClassGrades(classId: number){
    return ['/', 'account', ConfigService.accountId.getValue(), 'classes', classId,'grades'];
  }
  getClassAssignments(classId: number){
    return ['/', 'account', ConfigService.accountId.getValue(), 'classes', classId,'assignments'];
  }
  getSchoolYear(schoolYearId: number){
    return ['/', 'account', ConfigService.accountId.getValue(), 'settings', 'years', schoolYearId];
  }

  getTimeOff(){
    return ['/', 'account', ConfigService.accountId.getValue(), 'time-off'];
  }
  getNewSchool(){
    return ['/', 'account', ConfigService.accountId.getValue(), 'settings','years', 'new'];
  }
  getNewUser(): any[] {
    return ['/', 'account', ConfigService.accountId.getValue(), 'user', 'new'];
  }
  getNewClass(){
    return ['/', 'account', ConfigService.accountId.getValue(), 'classes', 'new'];
  }
}
