import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {animate, state, style, transition, trigger}     from "@angular/animations";
import {TextColorPipe}                              from "../../core/pipes/text-color.pipe";

@Component({
             selector: 'app-card-header',
             templateUrl: './card-header.component.html',
             styleUrl: './card-header.component.scss',
             animations: [
               trigger('detailExpand', [
                 state('collapsed,void', style({height: '0px', minHeight: '0', padding: '0rem'})),
                 state('expanded', style({height: '*', padding: '1rem'})),
                 transition(
                   'expanded <=> collapsed',
                   animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
                 ),
               ]),
             ],
           })
export class CardHeaderComponent {
  @Input() thumbnailUrl: string = "";
  @Input() title: string        = "";
  @Input() subtitle: string     = "";
  @Input() expandable: boolean  = true;
  @Input() expanded: boolean    = true;
  @Output() expandedChange      = new EventEmitter<boolean>();
  @Input() isLoading: boolean   = false;
  @Input() margin               = "m-4"
  @Input() backgroundColor      = '';
  @Input() helpUrl              = '';
  textColor: string             = '';
  textColorPipe                 = inject(TextColorPipe);
  _margin                       = this.margin;

  ngOnChanges(changes: any) {
    if (changes.backgroundColor) {
      this.textColor = this.textColorPipe.transform(this.backgroundColor);
    }
  }

  openHelpUrl() {
    if (this.helpUrl) {
      window.open(this.helpUrl, '_blank');
    }
  }
}
