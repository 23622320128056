import {formatDate} from '@angular/common';

export function convertToOffsetDateTime(timeStr: string): string | null {
  if (timeStr == null){
    return null;
  }
  // Parse the time string into a Date object
  const date = new Date(timeStr);

  // Use Angular's formatDate to convert to a timezone-inclusive string
  // 'yyyy-MM-ddTHH:mm:ssZZZZZ' is the format for Java's OffsetDateTime
  // 'en-US' can be replaced with the appropriate locale
  return formatDate(date, 'yyyy-MM-ddTHH:mm:ssZZZZZ', 'en-US');
}

export function getDate(timeStr: string): string {
  // Parse the time string into a Date object
  const date = new Date(timeStr.split('T')[0]);
  // Use Angular's formatDate to convert to a timezone-inclusive string
  // 'yyyy-MM-ddTHH:mm:ssZZZZZ' is the format for Java's OffsetDateTime
  // 'en-US' can be replaced with the appropriate locale
  return formatDate(date, 'yyyy-MM-ddTHH:mm:ssZZZZZ', 'en-US');
}

/**
 * This will return the start of the day for the given date
 * If the date is only a string - like '2021-01-01' - it will be treated as 00:00 UTC
 * @param date
 */
export function startOfDay(date: Date): Date {
  let newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

/**
 * This will return a date for a string.
 * If the string is '2021-01-01', it will return back 00:00 local time
 * @param dateString
 */
export function parseDateLocal(dateString: string): Date {
  let [year, month, day] = dateString.split('-').map(Number);
  // Note: JavaScript's months are 0-based
  return new Date(year, month - 1, day);
}

export function copyMatchingKeyValues(target: any, source: any) {
  if (target) {
    return Object.keys(target).forEach((key) => {
      if (source[key] !== undefined) target[key] = source[key];
    });
  }
}

export function getLocalDate(date: Date): string {
  return formatDate(date, 'yyyy-MM-dd', 'en-US');
}


