import {HttpClient}                    from '@angular/common/http';
import {inject, Injectable}            from '@angular/core';
import {ConfigService}                 from '../config.service';
import {lastValueFrom, Observable, of} from 'rxjs';
import {Account}                       from '../../models/db/account';
import {UserDetailed}                  from "../../models/db/user-detailed";
import {Weather}                       from "../../models/db/weather";

@Injectable({
              providedIn: 'root'
            })
export class AccountService {
  private http: HttpClient                              = inject(HttpClient);
  private cache: { time: Date, data: Account[] } | null = null;

  /**
   * Gets a list of accounts for this user
   * @returns List of accounts
   */
  public async getAccounts(): Promise<Account[]> {
    if (this.cache && (new Date().getTime() - this.cache.time.getTime()) < 60000) {
      return this.cache.data;
    } else {
      const response = await this.http.get<Account[]>('/api/account/');
      const list     = await lastValueFrom(response);
      this.cache     = {time: new Date(), data: list};
      return list;
    }
  }

  /**
   * Gets a list of users on the account
   * @returns List of users
   */
  public getAccountUsers(): Observable<UserDetailed[]> {
    if (ConfigService.accountId.getValue() == null) {
      return of([]);
    }
    return this.http.get<UserDetailed[]>(
      '/api/account/' + ConfigService.accountId.getValue() + '/user'
    );
  }

  public async getWeather(): Promise<Weather | null> {
    if (ConfigService.accountId.getValue() == null) {
      return null;
    }
    const resp = this.http.get<Weather>(
      '/api/account/' + ConfigService.accountId.getValue() + '/weather'
    );
    return await lastValueFrom(resp);
  }

  public create(account: Account): Observable<Account> {
    this.cache = null;
    return this.http.post<Account>('/api/account', account);
  }

  public update(account: Account): Observable<Account> {
    this.cache = null;
    return this.http.put<Account>('/api/account', account);
  }

  /**
   * Deletes an account
   * @param id
   * @returns
   */
  public delete(id: number): Observable<any> {
    this.cache = null;
    return this.http.delete('/api/account/' + id);
  }

  public async get(id: number): Promise<Account> {
    if (this.cache && this.isCacheValid() && this.cache.data.some(account => account.id == id)) {
      return this.cache.data.find(account => account.id == id)!;
    } else {
      const response = await this.http.get<Account>('/api/account/' + id);
      const account  = await lastValueFrom(response);
      if (this.cache) {
        const index = this.cache.data.findIndex(account => account.id == id);
        if (index >= 0) {
          this.cache.data[index] = account;
        } else {
          this.cache.data.push(account);
        }
      }
      return account;
    }
  }

  private isCacheValid(): boolean {
    return this.cache && (new Date().getTime() - this.cache.time.getTime()) < 60000 ? true : false;
  }
}
